// General functions
import {isDefined} from "../GeneralFunctions";


/**
 * Filter out the block items based on tag
 *
 * @property {array} blockItems - all blocks' content in a page
 * @property {string} tag       - tag name that we woulk like to filter data through
 * @returns {array}             - return a new block data that contains the passed tag name (tag)
 */
export const filterData = (blockItems, tag) => { 
    if(blockItems !== null && blockItems !==[] && tag !== null){
        const new_blockItems = [...blockItems]; // Make a copy for the existing block items
        let block = [];
        let blockIndexes = [];
        new_blockItems.map((blockItem, index)=>{
            if(isDefined(blockItem.page_version_block_contentItems)){
                blockItem.page_version_block_contentItems.map((block_contentItem)=>{
                if(isDefined(block_contentItem.options) && isDefined(block_contentItem.options.files)){ // if block contains file/s 
                    block_contentItem.options.files.map((file)=>{
                        if(isDefined(file.id) && file.id === tag.document_id){
                            blockIndexes.push(index); // push an index for the tag into `blockIndexes` array
                        }
                    });
                }else if(isDefined(block_contentItem.options) && isDefined(block_contentItem.options.search) && (block_contentItem.options.search === "search.by.tag")){ // if block contains "Search by tag" block, then we don't need to remove it
                    blockIndexes.push(index); // push an index for the search block
                }
                });
            }
        });
        blockIndexes.map((i) => {
            block.push(new_blockItems[i]);
        });      
        return block;
    }
};

async function isSearchByTagIsExists(blockItems) {
    if(blockItems !== null && blockItems !==[]){
        let flag = false;
        blockItems.map((blockItem)=> {
            if(isDefined(blockItem.page_version_block_contentItems)){
                blockItem.page_version_block_contentItems.map((block_contentItem)=>{
                    if(isDefined(block_contentItem.options) && isDefined(block_contentItem.options.search) && (block_contentItem.options.search === "search.by.tag")){ // if block contains "Search by tag" block
                        flag = true; // means the page contents have search box in the page
                    }
                });
            }
        });
        return flag;
    }
}

export const isSearchBoxByTagIsExists = async (data) => {
    if(!isDefined(data)){
        return false;
    }else{
        const page_version_data = (data.page_versionItems || [])[0] || {};
        let isExist = await isSearchByTagIsExists(page_version_data.page_version_blockItems);  // the function is paused here until the promise is fulfilled
        return isExist ? true : false;
    }
};