import { createGlobalStyle } from "styled-components";

import hexToRgba from "hex-to-rgba";

export default createGlobalStyle`
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  input:focus, textarea:focus, select:focus, button:focus{
        outline: none;
  }
  
  figure {
    margin:0;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
  }

  ul, li {
    margin:0;
    padding:0;
  }

  a {
    cursor: pointer;
  }

  .gutterBottom {
    margin-bottom: 0.35rem;
  }

  .paragraph {
    margin-bottom: ${(p) => p.theme.spacing(2)}px;
  }

  .MuiButton-root {
    text-transform: none;
  }

  .editing-ui.MuiTypography-root {
    color: ${(p) => p.theme.palette.aria.text_primary};
  }

  .editing-ui.MuiButton-containedPrimary {
    color: white;
    background-color: ${(p) => p.theme.palette.aria.primary};

    path {
      fill: white;
    }
  }

  .editing-ui.MuiButton-outlinedPrimary {
    color: ${(p) => p.theme.palette.aria.primary};
    border: 1px solid ${(p) => hexToRgba(p.theme.palette.aria.primary, 0.5)};
  }

  .editing-ui.MuiButton-text{
    color: ${(p) => p.theme.palette.aria.primary};
  }

  .editing-ui .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${(p) => p.theme.palette.aria.primary};
  }

  .editing-ui .MuiFormLabel-root.Mui-focused {
    color: ${(p) => p.theme.palette.aria.primary};
  }

  .editing-ui.MuiCircularProgress-root {
    color: ${(p) => p.theme.palette.aria.primary};
    filter: drop-shadow(1px 1px 3px rgba(48,49,51,0.4));
  }

  .editing-ui .MuiIconButton-colorSecondary:hover {
    background-color:${(p) => hexToRgba(p.theme.palette.aria.primary, 0.04)};
  }

  .editing-ui .MuiRadio-colorPrimary.Mui-checked,
  .editing-ui .MuiRadio-colorSecondary.Mui-checked {
    color: ${(p) => p.theme.palette.aria.primary};
  }

  .editing-ui.MuiCheckbox-colorPrimary.Mui-checked {
    color:${(p) => p.theme.palette.aria.primary};
  }
`;
