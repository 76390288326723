import React from "react";

// Redux
import { connect, useDispatch, useSelector } from "react-redux";
import { SET_CURRENT_PAGE_ID, SET_IS_EDITOR, SET_SITE_EDIT_MODE } from "../../redux/Types";

// MUI
import { useTheme } from "@material-ui/core/styles";

import { MainContainer } from "./styles";

// Apollo GraphGL
import { useQuery, useLazyQuery} from "@apollo/client";
import { DOCS_ALL_TAGS, PAGE_VERSION } from "../../@global/queries";

// Utils
import { getPageVerVars, getURLvars, getSiteId } from "../../@utils/general";
import logger from "../../@global/logger";

// Components
import PageStatusBar from "./PageStatusBar";
import PageContent from "../PageContent";

// General functions
import {isDefined, getBlockData} from "../GeneralFunctions";

// Search functions
import {filterData, isSearchBoxByTagIsExists} from "../Search";

const PageRoute = (props) => {
  const {
    path,
    location,
    dynamic_path_data,
    page_data,
    page_block_selector_state,
    addNewBlockCb,
    is_edit_mode,
    page_ver_id_lookup,
    className,
  } = props;

  const ver_vars = getPageVerVars(page_ver_id_lookup, page_data.id);
  const url_vars = getURLvars(location.search);

  // Update index.html meta data and title
  const title_node = document.querySelector("title");
  const title_meta_node = document.querySelector("meta[property='og:title']");
  const desc_meta_node = document.querySelector("meta[property='og:description']");
  const image_meta_node = document.querySelector("meta[property='og:image']");

  if (page_data.title) {
    title_node.innerText = page_data.title;
    title_meta_node.setAttribute("content", page_data.title);
  }

  if (page_data.description) desc_meta_node.setAttribute("content", page_data.description);

  if (page_data.metaimage) image_meta_node.setAttribute("content", page_data.metaimage);

  // Hooks
  const dispatch = useDispatch();
  const current_uri = useSelector((state) => state.Nav.current_uri);
  const theme = useTheme();

  const [tagList, setTagList] = React.useState([]); // State variables - to store all tags into `tagList`
  const [page_blocks_data, setPage_version_data] = React.useState({});  // State variables - intial values for a page content. Note that the "page_version_blockItems" is all blocks' contents in a page
  const [isSearchByTagExist, setIsSearchByTagExist] = React.useState(false); // State variables - intial values is false. Check if a page/s have search box 
  const [pageBlockItems, setPageBlockItems] = React.useState([]); // State variables - intial values for all blocks in a page

  React.useEffect(() => {
    dispatch({ type: SET_IS_EDITOR, payload: page_data.is_editor });

    if (is_edit_mode && !page_data.is_editor) {
      dispatch({ type: SET_SITE_EDIT_MODE, payload: false });
    }

    // Tracking the currently requested page URI via Redux Reducer update
    // is necessary, as various app components outside of the Reach Router
    // need to know about the change (think Admin Bar), and the URI change
    // may be requested via browser's address bar manipulation

    const new_uri = path.replace(`/:dynamic_path_data`, "");

    if (new_uri !== current_uri) {
      dispatch({
        type: SET_CURRENT_PAGE_ID,
        payload: {
          id: page_data.id,
          uri: path.replace(`/:dynamic_path_data`, ""),
        },
      });
    }
  }, [path, current_uri, is_edit_mode, page_data.id, page_data.is_editor, dispatch]);

  const { loading, error, data } = useQuery(PAGE_VERSION, {
    onError: (error) => {
      logger.error(error);
    },
    variables: {
      page_id: page_data.id,
      id: ver_vars.id,
      active: ver_vars.active,
      share_token: url_vars.share_token,
    },
  });

  const [getTags] = useLazyQuery(DOCS_ALL_TAGS, { // Fetch all tags using GraphQl query `DOCS_ALL_TAGS`
      onCompleted: (data) => {
        setTagList(data.tagItems || []);
      },
  });

  /**
   * Filter data function
   *
   * @property {string} tag              - tag name that we woulk like to filter data through
   * @property {array} page_blocks_data  - all blocks' content in a page
   * @property {object} event            - determines if a checkbox is checked or not 
   * @returns {array}                    - return a new block data that filter out the page blocks data ("page_blocks_data") based on the passed tag name (tag)
  */
  const handleChange = (tag, page_blocks_data, event)=> {
    if(event.target.checked){ // When a tag is checked (using the checkbox)
      if(tag !== null){
        const blockItems = filterData(page_blocks_data.page_version_blockItems, tag); // filter out the block items, and then assign it into `page_version_data` 
        setPage_version_data({
          ...page_blocks_data,
          page_version_blockItems: blockItems
        });
      }
    } else { // When a tag is unchecked (using the checkbox)
      // in this case will return back the all block item
      setPage_version_data({
        ...page_blocks_data,
        page_version_blockItems: pageBlockItems
      });
    }
  }

  // useCallback is a React hook
  // is used for "loading","data", and "error" child component that are rendering repeatedly without the need for it
  const getLoadedData = React.useCallback(async () => {
    if(!loading){
      if(data){
        const page_version_data = getBlockData(data, is_edit_mode); // * getBlockData will return the current page contents
        const isSearchBoxExist = await isSearchBoxByTagIsExists(data);
        setIsSearchByTagExist(isSearchBoxExist);
        setPage_version_data({...page_version_data});
        setPageBlockItems(page_version_data.page_version_blockItems);
      }
    }
    
  }, [loading, data, error,is_edit_mode]); // every time "loading","data", "error" and/or "is_edit_mode" changed, the function will loaded again

  React.useEffect(() => {
    getTags({
      variables: {tag :""}, // this parameters will send to DOCS_ALL_TAGS GraphQl query where `tag` is the tag's name
    });
    getLoadedData();
  }, [getLoadedData]); // useEffect will run once and when loading, data, and/or error changes
  
  if (loading){
    return null;
  }
  if (error) {
    logger.error(error);
    return <p>`Error :( ${error}`</p>;
  }
  if (!data){
    return <p>No Page version data was returned by the query</p>;
  }

  // Render
  return ( 
    <>
      
      <MainContainer className={className} theme={theme}>
        {!is_edit_mode && isSearchByTagExist && tagList &&(
          <div className="checkbox-search-filter-group row">
            
            <h3 className="col-12">
              Search by document's tags:
            </h3>
            
            <i className="fa-duotone fa-camera-retro">  </i>
            {tagList.map((tag, index) =>
              !tag.tag ? null : (
                <div className="form-check col-3" key={index}>
                  <input 
                    className="form-check-input"
                    type="checkbox"
                    value={tag.tag}
                    onChange={(event)=>handleChange(tag, page_blocks_data, event)}
                    />
                  <label className="form-check-label">
                    {tag.tag}
                  </label>
              </div>
              )
            )}
          </div>     
        )}
        {is_edit_mode && (
          <PageStatusBar
            page_name={page_data.name}
            page_visibility={page_data.visibility}
            pageCb={() => {}}
            version_active={page_blocks_data.active}
            version_nr={page_blocks_data.version}
            version_id={page_blocks_data.id}
          />
        )}

        <PageContent
          page_id={page_data.id}
          page_uri={page_data.uri}
          // TODO: Also check for page status, when page visibility control is implemented
          is_locked={page_data.visibility !== "offline" && page_blocks_data.active}
          is_live={page_blocks_data.active}
          version_data={page_blocks_data}
          dynamic_path_data={dynamic_path_data}
          page_block_selector_state={page_block_selector_state}
          addNewBlockCb={addNewBlockCb}
          location_state={location.state}
        />
      </MainContainer>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    is_edit_mode: state.Editor.is_edit_mode,
    page_ver_id_lookup: state.Editor.page_ver_id_lookup,
  };
};

export default connect(mapStateToProps)(PageRoute);

PageRoute.whyDidYouRender = false;
