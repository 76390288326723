import gql from "graphql-tag";

// Queries

export const SITE_DATA = gql`
  query($id: ID!) {
    siteItem(id: $id) {
      id
      name
      title
      active
      home_uri
      theme
      is_admin
    }
  }
`;

export const SITE_LAYOUT = gql`
  query($site_id: ID!) {
    site_layoutItems(filters: { site_id: $site_id }) {
      id
      type
      weight
      layout_component
      site_layout_contentItems {
        id
        content
        options
      }
    }
  }
`;

export const PAGE = gql`
  query($id: ID!) {
    pageItem(id: $id) {
      id
      name
      title
      description
      metaimage
      metarobots
      uri
      created
      visibility
      is_editor
    }
  }
`;

export const PAGES = gql`
  query($site_id: ID!, $share_token: ID) {
    pageItems(filters: { site_id: $site_id, share_token: $share_token }) {
      id
      name
      title
      description
      metaimage
      metarobots
      uri
      created
      visibility
      is_editor

      page_layoutItems(filters: { hidden: true, share_token: $share_token }) {
        id
        site_layout_id
      }
    }
  }
`;

export const SEARCH_PAGES = gql`
  query($site_id: ID!, $search_str: String) {
    pageItems(filters: { site_id: $site_id, name: $search_str }) {
      id
      name
      title
      description
      metaimage
      metarobots
      uri
      created
      visibility
      is_editor
    }
  }
`;

/**
 * A standard Site Builder image data model
 *
 * @typedef EditableImageData
 * @type {object}
 * @property {string} [alt] - Alternative text
 * @property {string} link - Image Url
 * @property {string} [target] - Link target. One of ("_blank" | "_self")
 */

export const PAGE_VERSION = gql`
  query($page_id: ID!, $id: ID, $active: Boolean, $share_token: ID) {
    page_versionItems(
      filters: { page_id: $page_id, id: $id, active: $active, share_token: $share_token }
    ) {
      id
      version
      active
      updated
      page_version_blockItems(filters: { share_token: $share_token }) {
        id
        reference
        block_component
        weight
        page_version_block_contentItems(filters: { share_token: $share_token }) {
          id
          content
          options
        }
      }
    }
  }
`;

export const CUR_PAGE_AND_VERSION = gql`
  query($page_id: ID!, $ver_id: ID, $active: Boolean, $share_token: ID) {
    pageItems(filters: { id: $page_id, share_token: $share_token }) {
      id
      name
      visibility
      uri

      page_versionItems(filters: { id: $ver_id, active: $active, share_token: $share_token }) {
        id
        version
        active
        updated
      }
    }
  }
`;

export const SEARCH_PAGE_VERSION = gql`
  query($page_id: ID!, $version: Int) {
    page_versionItems(filters: { page_id: $page_id, version: $version }) {
      id
      version
      active
      updated
    }
  }
`;

/**
 * Nested Set Model menu item https://en.wikipedia.org/wiki/Nested_set_model
 * @typedef MenuLinkItem
 * @type {object}
 * @property {(string | number)} id - an ID
 * @property {string} name - menu link name
 * @property {string} link - menu link value
 * @property {string} type - one of "page" | "link" | "document"
 * @property {number} lft - left edge value
 * @property {number} rgt - right edge value
 * @property {object} options - options JSON object
 */

export const MENU = gql`
  query($id: ID, $site_id: ID) {
    menuItems(filters: { id: $id, site_id: $site_id }) {
      id
      name

      menu_linkItems {
        id
        name
        link
        type
        lft
        rgt
        options
      }
    }
  }
`;

/**
 * Site-wide menu item data
 * @typedef MenuItem
 * @type {object}
 * @property {(string | number)} id - an ID
 * @property {string} name - menu name
 * @property {MenuLinkItem[]} menu_linkItems - menu links
 */

export const ALL_MENUS = gql`
  query($site_id: ID!) {
    menuItems(filters: { site_id: $site_id}) {
      id
      name
    }
  }
`;


// Mutations

export const JOIN_SITE = gql`
  mutation($site_id: ID!) {
    joinSite(input: { site_id: $site_id }) {
      id
      username
      site_id
    }
  }
`;

export const UPDATE_SITE = gql`
  mutation($id: ID!, $home_uri: String) {
    updateSite(input: { id: $id, home_uri: $home_uri }) {
      __typename
      id
      name
      title
      active
      home_uri
      theme
      is_admin
    }
  }
`;

export const UPDATE_SITE_LAYOUT_BLOCK = gql`
  mutation($id: ID!, $component: String, $content: JSON, $options: JSON) {
    updateSiteLayout(
      input: { id: $id, layout_component: $component, content: $content, options: $options }
    ) {
      __typename
      id
      type
      layout_component

      site_layout_contentItems {
        __typename
        id
        content
        options
      }
    }
  }
`;

export const CREATE_PAGE = gql`
  mutation($site_id: ID!, $name: String!) {
    createPage(input: { site_id: $site_id, name: $name }) {
      __typename
      id
      title
      name
      description
      metaimage
      metarobots
      uri
      visibility
      is_editor

      page_layoutItems(filters: { hidden: true }) {
        __typename
        id
        site_layout_id
      }
    }
  }
`;

export const UPDATE_PAGE = gql`
  mutation(
    $id: ID!
    $name: String
    $uri: String
    $title: String
    $description: String
    $metaimage: String
  ) {
    updatePage(
      input: {
        id: $id
        name: $name
        uri: $uri
        title: $title
        description: $description
        metaimage: $metaimage
      }
    ) {
      __typename
      id
      name
      title
      description
      metaimage
      metarobots
      uri
      visibility
      is_editor
    }
  }
`;

export const UPDATE_PAGE_VISIBILITY = gql`
  mutation($id: ID!, $visibility: String!) {
    updatePageVisibility(input: { id: $id, visibility: $visibility }) {
      __typename
      id
      name
      title
      description
      metaimage
      metarobots
      uri
      visibility
      is_editor
    }
  }
`;

export const DELETE_PAGE = gql`
  mutation($id: ID!) {
    deletePage(input: { id: $id }) {
      __typename
      id
    }
  }
`;

export const CREATE_PAGE_VERSION = gql`
  mutation($page_id: ID!) {
    createPageVersion(input: { page_id: $page_id }) {
      __typename
      id
      version
      active
      updated

      page_version_blockItems {
        __typename
        id
        reference
        block_component
        weight
        page_version_block_contentItems {
          __typename
          id
          content
          options
        }
      }
    }
  }
`;

export const CLONE_PAGE_VERSION = gql`
  mutation($id: ID!) {
    cloneVersion(input: { id: $id }) {
      __typename
      id
      version
      active
      updated
    }
  }
`;

export const PUBLISH_PAGE_VERSION = gql`
  mutation($id: ID!) {
    publishPageVersion(input: { id: $id }) {
      __typename
      id
      version
      active
      updated
    }
  }
`;

export const DELETE_PAGE_VERSION = gql`
  mutation($id: ID!) {
    deletePageVersion(input: { id: $id }) {
      __typename
      id
    }
  }
`;

export const ADD_PAGE_BLOCK = gql`
  mutation(
    $page_version_id: ID!
    $component: String!
    $weight: Int
    $content: JSON
    $options: JSON
  ) {
    addBlockToPage(
      input: {
        page_version_id: $page_version_id
        block_component: $component
        weight: $weight
        content: $content
        options: $options
      }
    ) {
      __typename
      id
      reference
      block_component
      weight
      page_version_block_contentItems {
        __typename
        id
        content
        options
      }
    }
  }
`;

export const UPDATE_PAGE_BLOCK = gql`
  mutation($id: ID!, $component: String, $weight: Int, $content: JSON, $options: JSON) {
    updateBlock(
      input: {
        id: $id
        block_component: $component
        weight: $weight
        content: $content
        options: $options
      }
    ) {
      __typename
      id
      reference
      weight
      block_component
      page_version_block_contentItems {
        __typename
        id
        content
        options
      }
    }
  }
`;

export const DELETE_PAGE_BLOCK = gql`
  mutation($id: ID!) {
    deletePageBlock(input: { id: $id }) {
      __typename
      id
    }
  }
`;

export const MOVE_PAGE_BLOCK = gql`
  mutation($id: ID!, $delta: Int!) {
    moveBlock(input: { id: $id, delta: $delta }) {
      __typename
      id
      reference
      weight
      block_component
      page_version_block_contentItems {
        __typename
        id
        content
        options
      }
    }
  }
`;

export const CREATE_MENU_LINK = gql`
  mutation($menu_id: ID!, $name: String!, $link: String!, $type: String!, $options: JSON) {
    createMenuLink(
      input: { menu_id: $menu_id, name: $name, link: $link, type: $type, options: $options }
    ) {
      __typename
      id
      name
      link
      type
      options
      lft
      rgt
      depth
      path
    }
  }
`;

export const UPDATE_MENU_LINK = gql`
  mutation($id: ID!, $name: String, $link: String, $type: String, $options: JSON) {
    updateMenuLink(input: { id: $id, name: $name, link: $link, type: $type, options: $options }) {
      __typename
      id
      name
      link
      type
      options
      lft
      rgt
      depth
      path
    }
  }
`;

export const DELETE_MENU_LINK = gql`
  mutation($id: ID!) {
    deleteMenuLink(input: { id: $id }) {
      __typename
      id
    }
  }
`;

export const MOVE_MENU_LINKS = gql`
  mutation($items: [MoveLinksInput]!) {
    moveLinks(input: $items) {
      __typename
      id
      name
      link
      type
      options
      lft
      rgt
      depth
      path
    }
  }
`;

export const CREATE_MENU = gql`
  mutation($site_id: ID!, $name: String!) {
    createMenu(input: { site_id: $site_id, name: $name }) {
      __typename
      id
      name
    }
  }
`;

export const UPDATE_MENU = gql`
  mutation($id: ID!, $name: String!) {
    updateMenu(input: { id: $id, name: $name }) {
      __typename
      id
      name
    }
  }
`;

export const DELETE_MENU = gql`
  mutation($id: ID!) {
    deleteMenu(input: { id: $id }) {
      __typename
      id
    }
  }
`;

// Fragments

export const NEW_PAGE_FRAGMENT = gql`
  fragment NewPage on Page {
    id
    title
    name
    description
    metaimage
    metarobots
    uri
    visibility
    is_editor
  }
`;

export const NEW_VERSION_FRAGMENT = gql`
  fragment NewVersion on Page_versionType {
    id
    version
    active
    updated
  }
`;

export const NEW_PAGE_BLOCK_FRAGMENT = gql`
  fragment NewPageBlock on Page_version_blockType {
    id
    reference
    block_component
    weight
    page_version_block_contentItems {
      id
      content
      options
    }
  }
`;

export const NEW_MENU_LINK_FRAGMENT = gql`
  fragment NewMenuLink on MenuLink {
    id
    name
    link
    type
    options
    lft
    rgt
    depth
    path
  }
`;

export const NEW_MENU_FRAGMENT = gql`
  fragment NewMenu on MenuType {
    id
    name
  }
`;

//
// Depricated
//
//

export const NEWS_FEED = gql`
  query($fromIndex: Int!, $first: Int!, $tags: [String]) {
    newsItemFeed(first: $first, fromIndex: $fromIndex, filters: { tags: $tags }) {
      totalCount
      pageInfo {
        nextIndex
        hasNextSlice
      }
      nodes {
        id
        title
        uri
        abstract
      }
    }
  }
`;

export const NEWS = gql`
  query($uri: String!) {
    newsItems(filters: { uri: $uri }) {
      title
      content
      image
    }
  }
`;

export const EVENT_FEED = gql`
  query($fromIndex: Int!, $first: Int!, $tags: [String]) {
    eventItemFeed(first: $first, fromIndex: $fromIndex, filters: { tags: $tags }) {
      totalCount
      pageInfo {
        nextIndex
        hasNextSlice
      }
      nodes {
        id
        title
        uri
        abstract
        date
        closes
        registration_date
        registration_closes
        location
        country
      }
    }
  }
`;

export const EVENT = gql`
  query($uri: String!) {
    eventItems(filters: { uri: $uri }) {
      title
      uri
      date
      closes
      registration_date
      registration_closes
      content
      contact_name
      contact_email
      location
      country
      lat
      lng
      timezone
      image
    }
  }
`;

export const DOCS_SEARCH = gql`
  query($site_id: ID!, $key: String!) {
    documentItems(filters: { site_id: $site_id, title: $key, hidden: 0 }) {
      id
      title
      description
      filename
      added
      updated
      hidden
      download
      tagItems {
        id
        tag
        document_id
      }
    }
  }
`;

export const DOCS_TAG_SEARCH = gql`
  query($site_id: ID!, $key: String!) {
    documentItems(filters: { site_id: $site_id, document_id: $key }) {
      tagItems {
        id
        tag
        document_id
      }
    }
  }
`;

export const DOCS_ALL = gql`
  query($site_id: ID!) {
    documentItems(filters: { site_id: $site_id}) {
      id
      title
      description
      filename
      added
      tagItems {
        id
        tag
        document_id
      }

    }
  }
`;

export const DOCS_ALL_TAGS = gql`
  query($tag: String!) {
    tagItems(filters: { tag: $tag}) {
        id
        tag
        document_id
    }
  }
`;

export const GENERATE_PAGE_SHARE_TOKEN = gql`
  mutation($page_id: ID!, $expires: DateTime, $edit: Boolean, $version_id: ID) {
    generatePageShareToken(
      input: { page_id: $page_id, expires: $expires, edit: $edit, page_version_id: $version_id }
    ) {
      id
      token
      edit
      expires
    }
  }
`;

export const REAGENTS_SEARCH = gql`
  query($key: String!, $first: Int!, $from: Int!) {
    reagentItemFeed(first: $first, fromIndex: $from, filters: { hidden: false, search: $key }) {
      totalCount
      pageInfo {
        endCursor
        nextIndex
        hasNextSlice
      }
      nodes {
        id
        sku
        name
        short
        available
        reagent_field_dataItems {
          field_id
          data
          reagent_fieldItems {
            ref
            name
          }
        }
      }
    }
  }
`;

export const REAGENT_DETAILS = gql`
  query($id: ID!) {
    reagentItem(id: $id) {
      id
      sku
      name
      short
      available
      reagent_field_dataItems {
        field_id
        data
        reagent_fieldItems {
          ref
          name
        }
      }
    }
  }
`;

export const CALL = gql`
  query($id: ID!) {
    callItem(id: $id) {
      id
      name
      short
      description
      guide
      open
      close
      call_fieldsItems {
        fid
        ref
        call_id
        type
        required
        order
        cursor

        title
        options

        alttitle
        altoptions
      }
    }
  }
`;
