export const isDefined = (param) => { // Function to check if a variable/array is empty or not 
    return (param !== null && param !== undefined) ? true : false;
}

 /**
   * Get the current page contents
   *
   * @property {object} data - 
   * @returns {array}        - return page contents if exists
  */
  export const getBlockData = (data, is_edit_mode) => {
    if(data !== null && data !==[]){
      let newBlocks = [];
      let blockIndexes = [];
      const page_version_data = (data.page_versionItems || [])[0] || {};
      if(is_edit_mode){
        return page_version_data;
      }else{
        if(isDefined(page_version_data)){
          // We need to discard the search box of being showing in the page
          // As long we can't modify the object (page_version_data), so we've created a copy of it, then we modified the new created object
          // We've assigned the old object to the new object. Initially, we did the copy without the propority 'page_version_blockItems'         
          const dynamicKey = "page_version_blockItems"; // removes property based on the dynamic key (page_version_blockItems)
          let {[dynamicKey]: _, ...new_page_version_data} = page_version_data; // Copy the object
          const blocks = page_version_data.page_version_blockItems; // Make a copy for the existing block items
          blocks.map((blockItem, index)=>{
            if(isDefined(blockItem.block_component) && blockItem.block_component !== 'Search_001'){
              blockIndexes.push(index); // push an index for the search block
            }
          });
          blockIndexes.map((i) => {
            newBlocks.push(blocks[i]);
          });
          new_page_version_data.page_version_blockItems = newBlocks; // assign the new block that discard the search box
          return new_page_version_data;
        }
      }
    }
  };
  

  